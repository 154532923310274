<template>
  <div
    class="bg-white relative"
  >
    <div class="flex py-3 px-4 items-center justify-between">
      <div class="text-xs musicName">
        <div class="font-h font-semibold text-primary musicName whitespace-nowrap overflow-ellipsis overflow-hidden">
          {{blockmodeConfig.name}}
        </div>
        <div v-if="blockmodeConfig.totalMs" class="mt-0.25 text-primary-600">
          {{humanLength(blockmodeConfig.totalMs/1000)}}
        </div>
      </div>

      <div v-if="!isEmpty" class="flex text-xs text-primary-500 items-center">
        Select which portion of the music you want here, & drag <div class="mx-1 dragPill" />to adjust length 🙂
      </div>
      <div v-else class="text-xs text-primary-500">
        Move capsules, or restore deleted portions inside them to edit overlapping audio
      </div>

      <div class="flex items-center">
        <div
          class="cursor-pointer mr-3 text-primary-500 hover:text-primary-700"
          :class="[canZoomToFit ? 'text-primary-500 hover:text-primary' : 'text-primary-300', zoomToFit ? 'text-violet hover:text-violet bg-primary-200 rounded-lg' : '']"
          @click="zoomToFit = !zoomToFit;"
          v-tooltip.top="'Zoom to Fit'"
        >
          <ZoomIcon class="h-7 w-7"/>
        </div>
        <div
          class="cursor-pointer mr-2.5 text-primary-500 hover:text-primary"
          @click="openModal({ name: 'AddFile',
          props: {
            soundMode: true,
            isReplacing: true
          } })"
          v-tooltip.top="'Replace Audio'"
        >
          <ReplaceIcon class="h-6 w-6" />
        </div>
        <div class="cursor-pointer mr-3 text-primary-500 hover:text-red" @click="deleteMusic" v-tooltip.top="'Delete Audio'">
          <DeleteIcon class="h-6 w-6" />
        </div>
        <div class="cursor-pointer text-primary-500 hover:text-red" @click="close()">
          <CloseIcon class="h-6 w-6" />
        </div>
      </div>
    </div>

    <div class="bg-primary-100 blockContainer relative">
      <div v-if="isEmpty" class="flex bg-primary-100 bg-opacity-50 top-0 right-0 bottom-0 left-0 z-50 absolute items-center justify-center">
        <div class="bg-primary-100 text-sm py-1 px-20 text-primary-400 leading-9 select-none">
          <span class="filter grayscale">⚠️</span> NO OVERLAPPING AUDIO
        </div>
      </div>
      <BlockEditor
        :isEmpty="isEmpty"
        :zoomToFit="canZoomToFit ? zoomToFit : false"
        :currentTime="currentTime"
        :audioTimeMs="blockmodeConfig.audioTimeMs"
        :audioText="blockmodeConfig.audioText"
        :totalMs="blockmodeConfig.totalMs"
        :fromMs="blockmodeConfig.fromMs"
        :toMs="blockmodeConfig.toMs"
        :fadeInMs="blockmodeConfig.fadeInMs"
        :fadeOutMs="blockmodeConfig.fadeOutMs"
        :scaledVolume="blockmodeConfig.volume"
        :showPlaybackBar="isPlaying"
        @volumeChanged="volumeChanged"
        @fadeInChanged="fadeInChanged"
        @fadeOutChanged="fadeOutChanged"
        @selectionChanged="selectionChanged"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReplaceIcon from '@/components/base/icons/Replace.vue'
import CloseIcon from '@/components/base/icons/CloseCircle.vue'
import DeleteIcon from '@/components/base/icons/Delete.vue'
import ZoomIcon from '@/components/base/icons/ZoomToFit.vue'

import { humanLength } from '@/utilities/utility'
import BlockEditor from './BlockEditor.vue'

export default {
  name: 'BlockMode',
  components: {
    ReplaceIcon,
    ZoomIcon,
    DeleteIcon,
    CloseIcon,
    BlockEditor
  },
  data: function() {
    return {
      humanLength,
      zoomToFit: false
    }
  },
  computed: {
    ...mapGetters({
      updateCount: 'blockmode/updateCount',
      blockmodeConfig: 'blockmode/blockmodeConfig',
      currentTime: 'doc/currentTime',
      isPlaying: 'editor/isPlaying'
    }),
    isEmpty: function() {
      return this.blockmodeConfig.toMs - this.blockmodeConfig.fromMs < 300
    },
    canZoomToFit: function() {
      const { totalMs, fromMs, toMs } = this.blockmodeConfig
      return totalMs > (toMs - fromMs)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      updateConfig: 'blockmode/update',
      close: 'blockmode/close',
      deleteMusic: 'blockmode/deleteMusic'
    }),
    update: function(payload) {
      this.updateConfig({
        ...payload,
        blockmodeUpdate: true
      })
    },
    volumeChanged: function(volume) {
      this.update({ volume })
    },
    fadeInChanged: function(fadeInMs) {
      this.update({ fadeInMs })
    },
    fadeOutChanged: function(fadeOutMs) {
      this.update({ fadeOutMs })
    },
    selectionChanged: function(selection) {
      this.update({
        fromMs: selection[0],
        toMs: selection[1]
      })
    }
  }
  // watch: {
  //   updateCount: function () {
  //     console.table(this.blockmodeConfig)
  //   }
  // }
}
</script>

<style scoped lang="less">
.blockContainer {
  height: 132px;
}

.dragPill {
  display: inline-block;
  height: 21px;
  width: 33px;

  background-image: url('../../../../../assets/icons/editor/LeftPillMusic.svg');
  background-position: center center;
  background-repeat:no-repeat;
  background-size: cover;
}

.musicName {
  width: 126px;
}
</style>
