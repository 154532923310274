<template>
  <div class="blockEditor" ref="blockEditor">
    <!-- Waveform fader at start -->
    <div class="fade-waveform" :style="leftOverlayStyle">
      <div class="h-4.5 opacity-1 top-0 right-0 left-0 z-10 greyBg absolute"/>
      <div
        class="opacity-80 top-4.5 right-0 left-0 z-10 greyBg absolute"
        :style="{height: `${selectionHeight + 32}px`}"
      />

      <div v-if="!isEmpty && audioOverflow" class="top-0 right-0 z-20 thinBlockedBar absolute"/>
      <div
        v-else-if="!isEmpty && dragX === 0"
        class="top-0 z-20 blockedBar absolute"
        :class="[zoomToFit ? '-right-0.5' : 'right-0']"
      />
      <div v-else-if="!isEmpty" class="top-0 right-0 z-20 lShadow absolute"/>
    </div>

    <!-- Main selection area with waveform and trapezoid canvas -->
    <div
      class="selection-wrapper relative"
      ref="selectionWrapper"
      :style="{
          height: `${selectionHeight + 50}px`,
          width: audioOverflow ? `${selectionWidth + 42}px` : `${selectionWidth}px`,
        }"
    >
      <!-- placeholder for timeline stamps -->
      <div class="bg-white flex h-4.5"/>
      <!-- timeline -->
      <div
        class="top-0 right-0 left-0 absolute overflow-x-hidden"
        :style="{
            height: `${selectionHeight + 18}px`,
            width: `${selectionWidth}px`,
          }"
      >
        <div class="top-0 absolute" :style="timelineStyle">
          <!-- timeline stamps -->
          <div class="flex h-4.5 items-center">
            <div
              v-for="(stamp, i) in timestamps"
              :key="i"
              class="text-right text-3xs text-primary-500"
              :style="{width: `${stamp.width}px`}"
            >
              {{humanLength(stamp.time)}}
            </div>
          </div>
          <div class="timeline-bg" :style="{height: `${selectionHeight}px`}" />
        </div>
      </div>

      <div class="flex">
        <div
          class="flex flex-col relative justify-end items-center"
          ref="dragX"
          :class="{'grab-error': (toMs - fromMs) >= totalMs}"
          :style="{ height: `${selectionHeight}px`, width: `${selectionWidth}px`}"
        >
          <!-- playback bar -->
          <div
            v-if="!isEmpty && showPlaybackBar"
            class="bg-primary flex flex-col -top-4.5 z-50 playbackBar absolute justify-between"
            :style="playbackBarStyle"
          >
            <div class="arrow-down" />
            <div class="arrow-up" />
          </div>

          <!--Dragger at top for volume -->
          <div
            v-show="!isEmpty && !zoomToFit"
            class="volume-top-fader"
            :style="volumeFaderStyle"
          >
            <div
              class="volume-controller"
              :style="{ width: `${selectionWidth}px` }"
            >
              <div
                class="upper-stroke"
                :style="upperStrokeStyle"
              />
              <div
                ref="volumeDrag"
                class="volume-dragger"
                :style="{
                  // width: `${upperStrokeWidth - 48}px`,
                  // left: `${startFadeX + 24}px`,
                  width: '28px',
                  left: `calc(50% - 14px)`
                }"
              >
                <div
                  class="flex draggerTooltip items-center justify-center"
                  v-tooltip="{
                    content: `Volume ${Math.round(volume * 100)}%`,
                    placement: 'top',
                    delay: 10
                  }"
                  :style="{
                    // left: `${volumePillLeft}px`
                  }"
                >
                  <div class="volumePill" />
                </div>
              </div>
            </div>
          </div>

          <!-- Start stroke and fader -->
          <div v-show="!isEmpty && !zoomToFit" class="start" :style="faderHeight">
            <div class="stroke" :style="startStrokeStyle" />
            <div ref="startFade" :style="startFaderStyle" class="start-fader">
              <div
                class="circle"
                v-tooltip="{
                  content: `Fade In ${Math.round(startFadeX * this.selectionMs / (this.selectionWidth * 100))/10}s`,
                  placement: 'top',
                  delay: 10
                }"
              />
            </div>
          </div>

          <div
            v-show="!isEmpty"
            ref="selection"
            class="selection"
            :style="selectionStyle"
          >
            <canvas ref="trapezoid" id="trapezoid" />
            <div
              :style="waveformStyle"
              ref="waveform"
              class="waveform"
            />
          </div>

          <!-- End stroke and fader -->
          <div v-show="!isEmpty && !zoomToFit" class="end" :style="faderHeight">
            <div class="stroke" :style="endStrokeStyle" />
            <div ref="endFade" class="end-fader" :style="endFaderStyle">
              <div
                class="circle"
                v-tooltip="{
                  content: `Fade Out ${Math.round(-endFadeX * this.selectionMs / (this.selectionWidth * 100))/10}s`,
                  placement: 'top',
                  delay: 10
                }"
              />
            </div>
          </div>

          <!-- Audio infos asolute positioned -->
          <div v-show="!isEmpty && !zoomToFit" class="bg-violet rounded text-white p-1 bottom-2.5 text-2xs absolute">
            {{humanLength(selectionMs/1000)}}
          </div>
          <div v-show="!isEmpty" class="rounded-tr bg-primary-600 text-white p-1 bottom-0 left-0 text-2xs absolute audioStartTime">
            {{humanLength((dragX * this.selectionMs) / (this.selectionWidth * 1000))}}
          </div>
        </div>

        <div v-if="audioOverflow" class="flex-grow flex flex-col p-1 relative musicNotAvailable items-center justify-end">
          <div class="text-primary text-2xs">
            {{humanLength(Math.round((toMs - fromMs - totalMs)/1000))}}
          </div>
        </div>
      </div>

      <div
        class="bg-white flex h-8 text-xs px-1.5 text-primary-700 z-30 relative whitespace-nowrap overflow-ellipsis overflow-hidden items-center"
        :class="{'opacity-20': isEmpty}"
      >
        <div v-if="shortAudioText[0]">{{shortAudioText[0]}}</div>
        <div class="flex-grow bg-primary-400 h-0.25 mx-2"/>
        <div v-if="shortAudioText[1]">{{shortAudioText[1]}}</div>
      </div>
    </div>

    <!-- Waveform fader at end -->
    <div class="right fade-waveform">
      <div class="h-4.5 opacity-1 top-0 right-0 left-0 z-10 greyBg absolute"/>
      <div
        class="opacity-80 top-4.5 right-0 left-0 z-10 greyBg absolute"
        :style="{height: `${selectionHeight + 32}px`}"
      />

      <div v-if="!isEmpty && audioOverflow" class="top-0 left-0 z-20 thinBlockedBar absolute"/>
      <div
        v-else-if="!isEmpty && dragX === limits.dragX.max"
        class="top-0 z-20 blockedBar absolute"
        :class="[zoomToFit ? '-left-0.5' : 'left-0']"
      />
      <div v-else-if="!isEmpty" class="top-0 left-0 z-20 rShadow absolute"/>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import debounce from 'lodash/debounce'
import { humanLength } from '@/utilities/utility'

const volumeScale = 100
let limits = {
  dragX: { min: 0, max: 0 },
  volume: { min: 0, max: 1 },
  fadeX: { min: 0, max: 0 }
}

export default {
  name: 'BlockEditor',
  data() {
    return {
      containerWidth: 0,
      selectionHeight: 82,
      dragX: 0,
      startFadeX: 0,
      endFadeX: 0,
      volume: this.scaledVolume / volumeScale,

      // other
      limits,
      humanLength
    }
  },
  props: {
    totalMs: Number,
    toMs: Number,
    fromMs: Number,
    fadeInMs: Number,
    fadeOutMs: Number,
    scaledVolume: Number,
    audioTimeMs: Array,

    zoomToFit: Boolean,
    currentTime: Number,
    audioText: String,
    showPlaybackBar: Boolean,
    isEmpty: Boolean

  },
  mounted() {
    this.containerWidth = this.$refs.blockEditor.clientWidth
    this.initInteract()
    this.initStartFadeInteract(this.$refs.startFade)
    this.initEndFadeInteract(this.$refs.endFade)
    this.initVolumeDragInteract(this.$refs.volumeDrag)
    this.updateCanvas()
    this.refreshLimits()
  },
  created: function() {
    this.refreshPositions()
  },
  computed: {
    audioOverflow: function() {
      return this.toMs - this.fromMs - this.totalMs >= 500
    },
    isAudioOverflowing: function() {
      return this.toMs - this.fromMs >= this.totalMs
    },
    selectionMs: function() {
      return this.toMs - this.fromMs
    },
    selectionWidth: function() {
      if (this.zoomToFit) return (this.containerWidth * this.selectionMs / this.totalMs)
      return 460
    },
    waveformWidth: function() {
      if (this.isEmpty || this.audioOverflow) return this.selectionWidth
      return (this.selectionWidth * this.totalMs) / this.selectionMs
    },
    leftOverlayStyle: function() {
      const leftOverlayWidth = this.dragX
      if (this.zoomToFit) {
        return {
          'min-width': `${leftOverlayWidth}px`,
          'max-width': `${leftOverlayWidth}px`
        }
      }
      return {}
    },
    matchHeight: function() {
      return this.selectionHeight * this.volume
    },
    selectionStyle: function() {
      return {
        width: `${this.selectionWidth}px`,
        height: `${this.matchHeight}px`
      }
    },
    waveformStyle: function() {
      return {
        ...this.audioOverflow ? {} : { transform: `translateX(${-this.dragX}px)` },
        // transform: `translateX(${-this.dragX}px)`,
        'min-width': `${this.waveformWidth}px`,
        'max-width': `${this.waveformWidth}px`,
        height: `54px`
      }
    },
    timelineStyle: function() {
      return {
        ...this.audioOverflow ? {} : { transform: `translateX(${-this.dragX}px)` },
        // transform: `translateX(${-this.dragX}px)`,
        'min-width': `${this.waveformWidth}px`,
        'max-width': `${this.waveformWidth}px`,
        height: `100px`
      }
    },
    timestamps: function() {
      const timeInSeconds = this.totalMs / 1000
      let noOfStamps = Math.floor(this.waveformWidth / 56)
      if (noOfStamps > timeInSeconds) {
        noOfStamps = Math.floor(timeInSeconds)
      }

      return Array.from(Array(noOfStamps).keys()).map(t => ({
        time: Math.floor((t + 1) * timeInSeconds / noOfStamps),
        width: Math.floor(this.waveformWidth / noOfStamps)
      }))
    },
    // Other
    volumeFaderStyle: function() {
      return {
        height: `${1 +
          (1 - parseFloat(this.volume)) * parseFloat(this.selectionHeight)}px`
      }
    },
    faderHeight: function() {
      return { height: `${this.matchHeight}px` }
    },
    startStrokeStyle: function() {
      return {
        transform: `skewX(-${(180 *
          Math.atan(this.startFadeX / this.matchHeight)) /
          Math.PI}deg)`
      }
    },
    startFaderStyle: function() {
      return { transform: `translateX(${this.startFadeX}px)` }
    },
    upperStrokeWidth: function() {
      return this.selectionWidth + this.endFadeX - this.startFadeX
    },
    volumePillLeft: function() {
      return (this.selectionWidth / 2) - this.startFadeX - 24 - 14
    },
    upperStrokeStyle: function() {
      return {
        transform: `translateX(${this.startFadeX}px)`,
        width: `${this.selectionWidth + this.endFadeX - this.startFadeX}px`
      }
    },
    endStrokeStyle: function() {
      return {
        transform: `skewX(${(180 *
          Math.atan(-this.endFadeX / this.matchHeight)) /
          Math.PI}deg)`
      }
    },
    endFaderStyle: function() {
      return { transform: `translateX(${this.endFadeX}px)` }
    },
    playbackBarStyle: function() {
      const duration = Math.min(this.selectionMs, this.totalMs)
      const timeInMs = this.currentTime * 1000

      if (timeInMs < this.audioTimeMs[0]) return { left: 0, display: 'none' }
      if (timeInMs > (this.audioTimeMs[0] + duration)) return { left: `${this.selectionWidth}px`, display: 'none' }
      return { left: `${this.selectionWidth * (timeInMs - this.audioTimeMs[0]) / duration}px` }
    },
    shortAudioText: function() {
      const isSilent = word => word.split('').every(t => t === '-' || t === ' ')
      const parts = this.audioText.split(' ')
      const reversedParts = [...parts].reverse()
      let firstWord = reversedParts.pop() || ''

      parts.forEach(el => {
        if (reversedParts.length && isSilent(firstWord)) {
          firstWord = firstWord + ' ' + reversedParts.pop()
        }
      })

      let lastWord = parts.pop() || ''
      reversedParts.forEach(el => {
        if (parts.length && isSilent(lastWord)) {
          lastWord = parts.pop() + ' ' + lastWord
        }
      })

      return [firstWord, lastWord]
    }
  },
  methods: {
    refreshLimits: function() {
      // Set max limit for drag event
      limits.dragX.max = this.waveformWidth - this.selectionWidth
      // Set limit for fade event
      limits.fadeX.max =
        0.45 * this.selectionMs *
        (this.selectionWidth / this.selectionMs)
    },
    refreshPositions: function() {
      if (this.fromMs >= 0) {
        this.dragX = this.fromMs * this.selectionWidth / this.selectionMs
      }

      if (this.fadeInMs >= 0) {
        this.startFadeX = this.fadeInMs * this.selectionWidth / this.selectionMs
      }

      if (this.fadeOutMs >= 0) {
        this.endFadeX = -this.fadeOutMs * this.selectionWidth / this.selectionMs
      }

      if (this.scaledVolume > 0) {
        this.volume = this.scaledVolume / volumeScale
      }
    },
    // Canvas
    updateCanvas: function() {
      var canvas = document.getElementById('trapezoid')
      var shape = canvas.getContext('2d')
      canvas.height = this.matchHeight
      canvas.width = this.selectionWidth
      shape.clearRect(
        0,
        0,
        this.selectionWidth,
        this.matchHeight
      )
      shape.fillStyle = 'rgba(131, 56, 236, 0.1)'
      shape.beginPath()
      shape.moveTo(this.startFadeX, 0)
      shape.lineTo(this.selectionWidth + this.endFadeX, 0)
      shape.lineTo(this.selectionWidth, this.matchHeight)
      shape.lineTo(0, this.matchHeight)
      shape.closePath()
      shape.fill()
    },
    // drag X
    initInteract: function() {
      const target = this.$refs.dragX
      if (!this.isAudioOverflowing && !this.isEmpty) {
        interact(target).unset()
        interact(target).draggable({
          enabled: true,
          inertia: true,
          onmove: this.dragMoveListener,
          cursorChecker: this.dragCursorChecker
        })
      } else {
        interact(target).draggable({
          enabled: false,
          inertia: true,
          onmove: this.dragMoveListener,
          cursorChecker: this.dragCursorChecker
        })
      }
    },
    dragCursorChecker: function(action, interactable, element, interacting) {
      // console.log('interact', action, interactable, element)
      if (interacting && this.dragX <= limits.dragX.min + 10) {
        return 'url(/images/right-only.svg) 13 0,zoom-out'
      } else if (interacting && this.dragX >= limits.dragX.max - 10) {
        return 'url(/images/left-only.svg) 13 0,zoom-out'
      } else if (interacting) {
        return 'url(/images/grab.svg) 13 0,zoom-out'
      } else {
        return 'url(/images/open-hand.svg) 8 0,zoom-out'
      }
    },
    dragMoveListener: function(event) {
      let dragX = this.dragX + event.dx
      if (dragX < limits.dragX.min) {
        dragX = limits.dragX.min
      } else if (dragX > limits.dragX.max) {
        dragX = limits.dragX.max
      }
      this.dragX = dragX

      const startTime = (dragX * this.selectionMs) / this.selectionWidth
      const endTime = startTime + this.selectionMs
      this.selectionChanged([startTime, endTime], dragX)
    },
    // drag fade in
    initStartFadeInteract: function(selector) {
      interact(selector).draggable({
        inertia: true,
        onmove: this.onFadeStartMove,
        cursorChecker: () => 'url(/images/fade-cursor.svg) 0 0,zoom-out'
      })
    },
    onFadeStartMove: function(event) {
      let startFadeX = parseFloat(this.startFadeX) + event.dx
      if (startFadeX < limits.fadeX.min) {
        startFadeX = limits.fadeX.min
      } else if (startFadeX > limits.fadeX.max) {
        startFadeX = limits.fadeX.max
      }
      this.startFadeX = startFadeX

      const duration =
        startFadeX * this.selectionMs / this.selectionWidth
      this.fadeInChanged(duration)
    },
    // drag fade out
    initEndFadeInteract: function(selector) {
      interact(selector).draggable({
        inertia: true,
        onmove: this.onFadeEndMove,
        cursorChecker: () => 'url(/images/fade-cursor.svg) 0 0,zoom-out'
      })
    },
    onFadeEndMove: function(event) {
      let endFadeX = parseFloat(this.endFadeX) + event.dx
      if (endFadeX < -limits.fadeX.max) {
        endFadeX = -limits.fadeX.max
      } else if (endFadeX > limits.fadeX.min) {
        endFadeX = limits.fadeX.min
      }
      this.endFadeX = endFadeX

      const duration = -endFadeX * this.selectionMs / this.selectionWidth
      this.fadeOutChanged(duration)
    },
    // drag volume
    initVolumeDragInteract: function(selector) {
      interact(selector).draggable({
        inertia: true,
        onmove: this.onVolumeMove,
        cursorChecker: () => 'url(/images/volume-cursor.svg) 0 0,zoom-out'
      })
    },
    onVolumeMove: function(event) {
      let volume =
        (parseFloat(this.selectionHeight) * parseFloat(this.volume) -
          event.dy) /
        parseFloat(this.selectionHeight)
      if (volume < (1 / volumeScale)) {
        volume = 1 / volumeScale
      } else if (volume > 1) {
        volume = 1
      }
      this.volume = volume
      this.volumeChanged(volume)
    },
    // Events
    volumeChanged: debounce(function(volume) {
      this.$emit('volumeChanged', Math.round(volume * volumeScale))
    }, 400),
    fadeInChanged: debounce(function(fadeIn) {
      this.$emit('fadeInChanged', fadeIn)
    }, 400),
    fadeOutChanged: debounce(function(fadeOut) {
      this.$emit('fadeOutChanged', fadeOut)
    }, 400),
    selectionChanged: debounce(function(selection, dragX) {
      console.log(dragX)
      this.$emit('selectionChanged', selection)
    }, 400),

    // update all
    updateAll: function() {
      this.updateCanvas()
      this.refreshPositions()
      this.refreshLimits()
      this.initInteract()
    }
  },
  watch: {
    startFadeX: function() {
      this.updateCanvas()
    },
    endFadeX: function() {
      this.updateCanvas()
    },
    volume: function() {
      this.updateCanvas()
    },
    // prop updates
    scaledVolume: function() {
      this.updateAll()
    },
    toMs: function() {
      this.updateAll()
    },
    fromMs: function() {
      this.updateAll()
    },
    totalMs: function() {
      this.updateAll()
    },
    fadeInMs: function() {
      this.updateAll()
    },
    fadeOutMs: function() {
      this.updateAll()
    },
    zoomToFit: function() {
      this.updateAll()
    },
    isEmpty: function() {
      this.updateAll()
    }
  }
}
</script>

<style scoped lang="less">
.blockEditor {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: clip;
}

.selection-wrapper {
  background: #fff;
}

.timeline-bg {
  background-image: linear-gradient(90deg, #e5e7eb 4.55%, #ffffff 4.55%, #ffffff 50%, #e5e7eb 50%, #e5e7eb 54.55%, #ffffff 54.55%, #ffffff 100%);
  background-size: 20px;
}

.selection {
  position: relative;
}

.selection::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #C19BF5;
}

.waveform {
  position: absolute;
  bottom: 1px;
  left: 0;
  transform-origin: bottom;
  background-image: url(/images/waveform.svg);
  background-size: contain;
  background-repeat-y: no-repeat;
}

.volume-top-fader {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 51;
}

.volume-controller {
  position: relative;
}

.upper-stroke {
  height: 1px;
  background: #C19BF5;
}

.start,
.end {
  position: absolute;
  z-index: 51;
}

.start {
  left: 0;
}

.end {
  right: 0;
}

.start .stroke {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background: #C19BF5;
  transform-origin: bottom left;
}

.end .stroke {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background: #C19BF5;
  transform-origin: bottom left;
}

.fade-waveform {
  position: relative;
  z-index: 50;
  flex-grow: 1;
  opacity: 0.8;
  height: 132px;
}

.greyBg {
  background: #F9FAFB;
}

#trapezoid {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* Control buttons */
.volume-dragger {
  position: absolute;
  height: 20px;
  top: -10px;
  display: flex;
  align-items: center;

  .draggerTooltip {
    transition: all .2s;
    position: relative;
    width: 28px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .volumePill {
    width: 24px;
    height: 18px;
    background-image: url(/images/volume.svg);
  }

  &:hover .volumePill {
    width: 28px;
    height: 20px;
    background-image: url(/images/volume-hover.svg);
  }
}

.start-fader,
.end-fader {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  top: -9px;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    width: 16px;
    height: 16px;
    background-image: url(/images/fade.svg);
  }

  &:hover .circle {
    width: 18px;
    height: 18px;
    background-image: url(/images/fade-hover.svg);
  }
}

.start-fader {
  left: -9px;
}

.end-fader {
  right: -9px;
}

/* Effects */
.lShadow {
  height: 132px;
  width: 20px;
  background: linear-gradient(90deg, #E0E2E5 -23.81%, rgba(224, 226, 229, 0.185581) 35.74%, rgba(224, 226, 229, 0) 83.33%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.rShadow {
  height: 132px;
  width: 20px;
  background: linear-gradient(90deg, #E0E2E5 -23.81%, rgba(224, 226, 229, 0.185581) 35.74%, rgba(224, 226, 229, 0) 83.33%);
}

.blockedBar {
  height: 132px;
  width: 2px;
  background: #000228;
}

.thinBlockedBar {
  height: 132px;
  width: 1px;
  background: #666C7C;
  opacity: 0.2;
}

.playbackBar {
  height: 100px;
  width: 1px;
}

.musicNotAvailable {
  background: repeating-linear-gradient(
          45deg,
          #fff,
          #fff 2px,
          #E5E7EB 2px,
          #E5E7EB 4px
  );
}

.grab-error {
  cursor: url(/images/grab-error-finished.svg) 50 0,zoom-out;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #E2462C;
  transform: translateX(-50%);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #E2462C;
  transform: translateX(-50%);
}

.audioStartTime {
  z-index: 51;
}
</style>
