<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    :class="classname"
  >
    <path d="M19.4158 18.375C20.6016 16.9668 21.2513 15.1847 21.25 13.3438C21.25 11.78 20.7863 10.2515 19.9176 8.95128C19.0488 7.6511 17.814 6.63774 16.3693 6.03933C14.9247 5.44093 13.335 5.28436 11.8013 5.58942C10.2677 5.89448 8.8589 6.64748 7.75319 7.75319C6.64748 8.8589 5.89448 10.2677 5.58942 11.8013C5.28436 13.335 5.44093 14.9247 6.03933 16.3693C6.63774 17.814 7.6511 19.0488 8.95128 19.9176C10.2515 20.7863 11.78 21.25 13.3438 21.25C15.1847 21.2513 16.9668 20.6016 18.375 19.4158L23.3181 24.3367C23.5996 24.6169 24.0548 24.6164 24.3356 24.3356C24.6164 24.0548 24.6169 23.5996 24.3367 23.3181L19.4158 18.375ZM13.3438 19.8125C12.0644 19.8125 10.8137 19.4331 9.74991 18.7223C8.68613 18.0115 7.85701 17.0012 7.36741 15.8192C6.8778 14.6372 6.7497 13.3366 6.9993 12.0818C7.2489 10.8269 7.86499 9.67433 8.76966 8.76966C9.67433 7.86499 10.8269 7.2489 12.0818 6.9993C13.3366 6.7497 14.6372 6.8778 15.8192 7.36741C17.0012 7.85701 18.0115 8.68613 18.7223 9.74991C19.4331 10.8137 19.8125 12.0644 19.8125 13.3438C19.8106 15.0588 19.1285 16.703 17.9158 17.9158C16.703 19.1285 15.0588 19.8106 13.3438 19.8125Z" stroke="#949BA8" stroke-width="0.5"/>
    <path d="M11.1875 12.625H9.75V11.1875C9.75038 10.8064 9.90195 10.441 10.1715 10.1715C10.441 9.90195 10.8064 9.75038 11.1875 9.75H12.625V11.1875H11.1875V12.625Z" class="inner" />
    <path d="M16.9375 12.625H15.5V11.1875H14.0625V9.75H15.5C15.8811 9.75038 16.2465 9.90195 16.516 10.1715C16.7855 10.441 16.9371 10.8064 16.9375 11.1875V12.625Z" class="inner" />
    <path d="M12.625 16.9375H11.1875C10.8064 16.9371 10.441 16.7855 10.1715 16.516C9.90195 16.2465 9.75038 15.8811 9.75 15.5V14.0625H11.1875V15.5H12.625V16.9375Z" class="inner" />
    <path d="M15.5 16.9375H14.0625V15.5H15.5V14.0625H16.9375V15.5C16.9371 15.8811 16.7855 16.2465 16.516 16.516C16.2465 16.7855 15.8811 16.9371 15.5 16.9375Z" class="inner" />
  </svg>
</template>

<script>
export default {
  name: 'ZoomToFit',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
svg {
  fill: currentColor;

  &:hover .inner {
    fill: #8338EC;

  }
}
</style>
